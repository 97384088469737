import React from 'react'
import Markdown from 'react-markdown'

import style from './single.module.css'

const SR = ({data})=>{
	const image = data.feature_image ? data.feature_image.childImageSharp.fluid.src : null
	return(
		<>
			<div className={style.postWrapper}>
				<div className={style.headerWrap}>
					<div className={style.titleWrapper}>
						<h2>{data.name}</h2>
						<div className={style.postDate}>
							{new Date(data.created_at).toDateString()}
						</div>
					</div>
					<img src={image} alt=""/>
				</div>
				<div className={style.postContentWrapper}>
					<p dangerouslySetInnerHTML={{__html: data.excerpt}}/>
					<div className={style.contentWrapper}>
						<Markdown source={data.content} escapeHtml={true}/>
					</div>
					<div className={style.shareItem}>

					</div>
				</div>
			</div>
		</>
	)
}

export default SR